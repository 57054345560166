let acc = document.getElementsByClassName("accordion");
let container = document.getElementsByClassName("accordion-container");
let acc_button = document.getElementsByClassName("accordion-button");

for (let i = 0; i < acc.length; i++) {
  if(acc[i].innerText.length < 10){
    acc[i].parentElement.classList.add("hidden")
  }
  
  acc[i].addEventListener("click", function() {
    this.classList.toggle("active");
    let panel = this.nextElementSibling;
    if (panel.style.display === "block") {
      panel.style.display = "none";
      this.setAttribute("aria-expanded", "false");
    } else {
      panel.style.display = "block";
      this.setAttribute("aria-expanded", "true");
    }
  });
}

for (let j = 0; j < container.length; j++) {
  container[j].addEventListener("click", function() {
    this.classList.toggle("container-active");
 });
}

for (let k = 0; k < acc_button.length; k++) {
    acc_button[k].addEventListener("keyup", function(){
      this.classList.remove("remove-outline")
    })
    acc_button[k].addEventListener("click", function(){
      this.classList.add("remove-outline")
    })
  }